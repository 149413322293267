import { BsGithub, BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
const RightFooter = () => {
  return (
    <div>
      <h2 className="text-slate-200 font-bold text-2xl mt-2 font-[Varela]">
        STAY CONNECTED
      </h2>
      <div className="flex mt-3">
        <Link target={"_blank"} to={"https://www.instagram.com/hg.iiitranchi/"}>
          <BsInstagram className="mx-2 text-3xl insta text-[#C64A92] cursor-pointer" />
        </Link>
        <Link target={"_blank"} to={"https://twitter.com/_houseofgeeks/"}>
          <BsTwitter className="mx-2 text-3xl text-[#1E9BEB] cursor-pointer" />
        </Link>
        {/* <Link to={"https://discord.gg/QwVfq3n7"} target="_blank">
          <BsDiscord className="mx-2 text-3xl text-[#5562EA] cursor-pointer" />
        </Link> */}
        {/* <Link to={"http://nas.io/devs-dungeon"} target="_blank">
          <BsWhatsapp className="mx-2 text-3xl text-[#25D366] cursor-pointer" />
        </Link> */}
        <Link
          to={"https://www.linkedin.com/company/hgiiitranchi/"}
          target="_blank"
        >
          <BsLinkedin className="mx-2 text-3xl text-[#0077B5] cursor-pointer" />
        </Link>

        <Link to={"https://github.com/houseofgeeks"} target="_blank">
          <BsGithub className="mx-2 text-3xl text-[#0077B5] cursor-pointer" />
        </Link>
      </div>
    </div>
  );
};
export default RightFooter;
